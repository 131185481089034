import { graphql, Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import Layout from '../../components/Layout'
import * as styles from '../../styles/projects.module.css'

export default function Projects({ data }) {
    const projects = data.projects.nodes
    const contact = data.contact.siteMetadata.contact
    return (
        <Layout>
            <div className={styles.portfolio}>
                <h2>Projects</h2>
                <p>Isi project2</p>
                <div className={styles.projects}>
                    {projects.map(project => (
                        <Link to={"/projects/" + project.frontmatter.slug} key={project.id} >
                            <div>
                                <GatsbyImage image={project.frontmatter.thumb.childImageSharp.gatsbyImageData} alt="projects" />
                                <h3>{project.frontmatter.title}</h3>
                                <p>{project.frontmatter.stack}</p>
                            </div>
                        </Link>
                    ))}
                </div>
                <p>Like my projects? You can contact me at {contact}</p>
            </div>
        </Layout >
    )
}

// Cara panggil page query
export const query = graphql`
query ProjectsPage {
    projects: allMarkdownRemark(sort: {
        fields: frontmatter___date,
        order: DESC
    }) {
        nodes {
            frontmatter {
                title
                stack
                slug
                thumb {
                    childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
                    }
                }
            }
            id
        }
    }
    contact: site {
        siteMetadata {
            contact
        }
    }
}
`
